var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      ref: "formCreateClass",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitCreate.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "8" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Informações básicas" } },
                [
                  _c(
                    "b-card-text",
                    [
                      _c(
                        "b-row",
                        { staticClass: "form-row" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(" Título "),
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                  ]),
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid": _vm.$v.item.title.$error,
                                    },
                                    attrs: {
                                      required: "",
                                      placeholder: "Título",
                                    },
                                    model: {
                                      value: _vm.item.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "title", $$v)
                                      },
                                      expression: "item.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "label",
                                {
                                  staticStyle: { display: "flow-root" },
                                  attrs: { for: "" },
                                },
                                [
                                  _c("span", { staticClass: "float-left" }, [
                                    _vm._v("Subtítulo"),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { placeholder: "Subtítulo" },
                                    model: {
                                      value: _vm.item.subtitle,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "subtitle", $$v)
                                      },
                                      expression: "item.subtitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "pt-1" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Descrição"),
                                  ]),
                                  _c("quill-editor", {
                                    attrs: {
                                      options: _vm.snowOption,
                                      "auto-height": true,
                                    },
                                    model: {
                                      value: _vm.item.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "description", $$v)
                                      },
                                      expression: "item.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Professor(a)" } },
                [
                  _c(
                    "b-card-text",
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "mb-0" },
                        [
                          _c(
                            "v-select",
                            {
                              ref: "selectTeacher",
                              class: {
                                "is-invalid": _vm.$v.item.teacher.$error,
                              },
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o nome do professor",
                                options: _vm.optionsTeachers,
                              },
                              on: { search: _vm.fetchTeachersOptions },
                              model: {
                                value: _vm.item.teacher,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "teacher", $$v)
                                },
                                expression: "item.teacher",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectTeacher.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum professor encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { attrs: { header: "Vídeo e Streaming" } },
                [
                  _c("b-card-text", [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _vm.item.time && _vm.item.streaming
                          ? _c(
                              "div",
                              { staticClass: "col-12 pb-2" },
                              [
                                _c("b-embed", {
                                  attrs: {
                                    type: "iframe",
                                    aspect: "16by9",
                                    src: "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
                                    allowfullscreen: "",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Streaming "),
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("v-select", {
                                  class: {
                                    "is-invalid": _vm.$v.item.streaming.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    placeholder: "Selecione o streaming",
                                    required: "",
                                    searchable: false,
                                    "item-text": "title",
                                    "item-value": "code",
                                    options: _vm.optionsStreamings,
                                  },
                                  model: {
                                    value: _vm.item.streaming,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "streaming", $$v)
                                    },
                                    expression: "item.streaming",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "7" } },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Código do vídeo "),
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  class: {
                                    "is-invalid": _vm.$v.item.code.$error,
                                  },
                                  model: {
                                    value: _vm.item.code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "code", $$v)
                                    },
                                    expression: "item.code",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "5" } },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Tempo "),
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: ["##:##"],
                                      expression: "['##:##']",
                                    },
                                  ],
                                  class: {
                                    "is-invalid": _vm.$v.item.time.$error,
                                  },
                                  attrs: {
                                    required: "",
                                    placeholder: "HH:MM",
                                    type: "tel",
                                  },
                                  model: {
                                    value: _vm.item.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "time", $$v)
                                    },
                                    expression: "item.time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-card",
                { attrs: { header: "Meterial" } },
                [
                  _c("b-card-text", [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c(
                            "div",
                            { staticClass: "form-group mb-0" },
                            [
                              _c(
                                "v-select",
                                {
                                  ref: "selectMeterial",
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título do material",
                                    options: _vm.materialsOptions,
                                  },
                                  on: { search: _vm.fetchMaterialsOptions },
                                  model: {
                                    value: _vm.item.material,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "material", $$v)
                                    },
                                    expression: "item.material",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectMeterial.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum professor encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-card",
                { attrs: { header: "Tag(s)" } },
                [
                  _c(
                    "b-card-text",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" Tag Tema "),
                          ]),
                          _c(
                            "v-select",
                            {
                              ref: "selectTema",
                              class: {
                                "is-invalid": _vm.$v.item.tags.subject.$error,
                              },
                              attrs: {
                                multiple: "",
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título da tag",
                                options: _vm.optionsTagTema,
                              },
                              on: { search: _vm.fetchTagsCategoryTema },
                              model: {
                                value: _vm.item.tags.subject,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item.tags, "subject", $$v)
                                },
                                expression: "item.tags.subject",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectTema.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum professor encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" Tag Concurso "),
                          ]),
                          _c(
                            "v-select",
                            {
                              ref: "selectContest",
                              class: {
                                "is-invalid": _vm.$v.item.tags.contest.$error,
                              },
                              attrs: {
                                multiple: "",
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título da tag",
                                options: _vm.optionsTagConcurso,
                              },
                              on: { search: _vm.fetchTagsCategoryConcurso },
                              model: {
                                value: _vm.item.tags.contest,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item.tags, "contest", $$v)
                                },
                                expression: "item.tags.contest",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectContest.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum professor encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { staticClass: "mb-0" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" Tag Linha do tempo "),
                          ]),
                          _c(
                            "v-select",
                            {
                              ref: "selectTimeline",
                              class: {
                                "is-invalid": _vm.$v.item.tags.timeline.$error,
                              },
                              attrs: {
                                multiple: "",
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título da tag",
                                options: _vm.optionsTagLinhaDoTempo,
                              },
                              on: { search: _vm.fetchTagsCategoryLinhaDoTempo },
                              model: {
                                value: _vm.item.tags.timeline,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item.tags, "timeline", $$v)
                                },
                                expression: "item.tags.timeline",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectTimeline.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum professor encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card-footer",
                    { staticClass: "px-0 pb-0 text-center" },
                    [_vm._v(" É possível selecionar mais de 1 tag ")]
                  ),
                ],
                1
              ),
              _c("ButtonsActionsFooter", {
                attrs: {
                  routerBack: "lessons-list",
                  variant: "success",
                  submited: _vm.submited,
                  text: "Cadastrar",
                  subtext: "Aguarde...",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }