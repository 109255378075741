<template>
  <b-form ref="formCreateClass" @submit.prevent="submitCreate">
    <b-row>
      <b-col md="8">
        <b-card header="Informações básicas">
          <b-card-text>
            <b-row class="form-row">
              <b-col md="6">
                <b-form-group>
                  <label for="">
                    Título
                    <i class="text-danger bi bi-record-circle"></i>
                  </label>
                  <b-form-input
                    required
                    v-model="item.title"
                    placeholder="Título"
                    :class="{ 'is-invalid': $v.item.title.$error }"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label for="" style="display: flow-root">
                  <span class="float-left">Subtítulo</span>
                </label>
                <b-form-group>
                  <b-form-input
                    v-model="item.subtitle"
                    placeholder="Subtítulo"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group class="pt-1">
                  <label for="">Descrição</label>
                  <quill-editor
                    v-model="item.description"
                    :options="snowOption"
                    :auto-height="true"
                  >
                  </quill-editor>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card header="Professor(a)">
          <b-card-text>
            <b-form-group class="mb-0">
              <v-select
                ref="selectTeacher"
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.teacher"
                placeholder="Digite o nome do professor"
                :class="{ 'is-invalid': $v.item.teacher.$error }"
                :options="optionsTeachers"
                @search="fetchTeachersOptions"
              >
                <span slot="no-options" @click="$refs.selectTeacher.open = false">
                  Nenhum professor encontrado
                </span>
              </v-select>
            </b-form-group>
          </b-card-text>
        </b-card>

        <b-card header="Vídeo e Streaming">
          <b-card-text>
            <div class="form-row">
              <div v-if="item.time && item.streaming" class="col-12 pb-2">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                  allowfullscreen
                />
              </div>
              <b-col md="12">
                <label for="">
                  Streaming
                  <i class="text-danger bi bi-record-circle"></i>
                </label>
                <b-form-group>
                  <v-select
                    label="title"
                    placeholder="Selecione o streaming"
                    required
                    :searchable="false"
                    item-text="title"
                    item-value="code"
                    v-model="item.streaming"
                    :options="optionsStreamings"
                    :class="{ 'is-invalid': $v.item.streaming.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <label for="">
                  Código do vídeo
                  <i class="text-danger bi bi-record-circle"></i>
                </label>
                <b-form-group>
                  <b-form-input
                    v-model="item.code"
                    :class="{ 'is-invalid': $v.item.code.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="5">
                <label for="">
                  Tempo
                  <i class="text-danger bi bi-record-circle"></i>
                </label>
                <b-form-group>
                  <b-form-input
                    required
                    placeholder="HH:MM"
                    v-mask="['##:##']"
                    type="tel"
                    v-model="item.time"
                    :class="{ 'is-invalid': $v.item.time.$error }"
                  />
                </b-form-group>
              </b-col>
            </div>
          </b-card-text>
        </b-card>

        <b-card header="Meterial">
          <b-card-text>
            <div class="form-row">
              <b-col md="12">
                <div class="form-group mb-0">
                  <v-select
                    ref="selectMeterial"
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="item.material"
                    placeholder="Digite o título do material"
                    :options="materialsOptions"
                    @search="fetchMaterialsOptions"
                  >
                    <span slot="no-options" @click="$refs.selectMeterial.open = false">
                      Nenhum professor encontrado
                    </span>
                  </v-select>
                </div>
              </b-col>
            </div>
          </b-card-text>
        </b-card>

        <b-card header="Tag(s)">
          <b-card-text>
            <b-form-group>
              <label for="">
                Tag Tema
              </label>
              <v-select
                ref="selectTema"
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.subject"
                placeholder="Digite o título da tag"
                :options="optionsTagTema"
                @search="fetchTagsCategoryTema"
                :class="{ 'is-invalid': $v.item.tags.subject.$error }"
              >
                <span slot="no-options" @click="$refs.selectTema.open = false">
                  Nenhum professor encontrado
                </span>
              </v-select>
            </b-form-group>
            
            <b-form-group>
              <label for="">
                Tag Concurso
              </label>
              <v-select
                ref="selectContest"
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.contest"
                placeholder="Digite o título da tag"
                :options="optionsTagConcurso"
                @search="fetchTagsCategoryConcurso"
                :class="{ 'is-invalid': $v.item.tags.contest.$error }"
              >
                <span slot="no-options" @click="$refs.selectContest.open = false">
                  Nenhum professor encontrado
                </span>
              </v-select>
            </b-form-group>

            <b-form-group class="mb-0">
              <label for="">
                Tag Linha do tempo
              </label>
              <v-select
                ref="selectTimeline"
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.timeline"
                placeholder="Digite o título da tag"
                :options="optionsTagLinhaDoTempo"
                @search="fetchTagsCategoryLinhaDoTempo"
                :class="{ 'is-invalid': $v.item.tags.timeline.$error }"
              >
                <span slot="no-options" @click="$refs.selectTimeline.open = false">
                  Nenhum professor encontrado
                </span>
              </v-select>
            </b-form-group>
          </b-card-text>
          <b-card-footer class="px-0 pb-0 text-center">
            É possível selecionar mais de 1 tag
          </b-card-footer>
        </b-card>

        <ButtonsActionsFooter
          routerBack="lessons-list"
          variant="success"
          :submited="submited"
          text="Cadastrar"
          subtext="Aguarde..."
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BCardFooter,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import ButtonsActionsFooter from '@/views/components/button/ButtonsActionsFooter';

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BEmbed,
    BCardText,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    quillEditor,
    ButtonsActionsFooter,
    BAvatar,
    BCardFooter,
  },
  data() {
    return {
      submited: false,
      filteredOptions: [],
      wasUploads: 0,
      snowOption: {
        theme: 'snow',
        placeholder: '',
        height: '300',
      },
      item: {
        title: '',
        code: '',
        time: '',
        subtitle: '',
        description: '',
        teacher: '',
        streaming: '',
        material: '',
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
      },
      optionsStreamings: [],
      optionsTeachers: [],
      materialsOptions: [],
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
    }
  },
  validations: {
    item: {
      title: {
        required,
        minLength: minLength(5),
      },
      teacher: {
        required,
      },
      streaming: {
        required,
      },
      code: {
        required,
      },
      time: {
        required,
      },
      tags: {
        subject: {
          required,
        },
        contest: {
          required,
        },
        timeline: {
          required,
        },
      }
    },
  },
  computed: {
    ...mapState('Lesson', ['lesson']),
    ...mapState('Media', ['searchMaterials']),
    ...mapState('Tag', ['searchTagsTema', 'searchTagsConcurso', 'searchTagsLinhadoTempo']),
  },
  methods: {
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {        
        this.$store.dispatch('Lesson/create', this.item)
        .then(() => {
          this.$router.push({ name: 'lessons-list' });
          this.notify(
            'Aula cadastrada com sucesso!',
            'UserCheckIcon',
            'success',
            'Agora é só vincular a disciplina.',
          );
        })
        .catch(() => {
          this.submited = false;
          this.notify(
            'Error ao cadastrar aula',
            'CoffeeIcon',
            'danger',
            'Verifique os dados e tente novamente.',
          );
        })
        .finally(() => {
          this.submited = false;
        });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
    async fetchTeachersOptions (term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch('Teacher/search', term)
      }
    },
    async fetchMaterialsOptions (term) {
      if (term.length > 2) {
        this.$store.dispatch('Media/searchMaterial', term).then(() => {
          this.materialsOptions = this.searchMaterials;
        });
      }
    },
    async fetchTagsCategoryTema (term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch('Tag/searchTags', { category_id: 1, term: term })
      }
    },
    async fetchTagsCategoryConcurso (term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch('Tag/searchTags', { category_id: 2, term: term })
      }
    },
    async fetchTagsCategoryLinhaDoTempo (term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch('Tag/searchTags', { category_id: 3, term: term })
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  async mounted() {
    this.optionsStreamings = await this.$store.dispatch("Streaming/forSelect");
  }
};
</script>

<style >
.beforeUpload .icon {
  width: 50px !important;
}
</style>
